/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react'
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg'
import { v4 as uuidV4 } from 'uuid'

import './Accordion.scss'

type Props = {
  title: React.ReactNode
  children: React.ReactNode
  defaultExpanded?: boolean
  className?: string
  handleClick?: () => void
}

export default function Accordion({
  title,
  children,
  defaultExpanded = true,
  className = '',
  handleClick = () => null,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleToggle = () => {
    handleClick()
    setExpanded(!expanded)
  }

  return (
    <div
      id={uuidV4()}
      className={`accordion${expanded ? ' expanded' : ''} ${className}`}
    >
      <h3 onClick={handleToggle} style={{ marginBottom: expanded ? '12px' : 0 }}>
        {title}
      </h3>
      <Chevron />
      <div
        className="wrapper"
        style={{
          height: expanded ? 'auto' : 0,
          overflow: expanded ? 'visible' : 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}
