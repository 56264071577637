import { post } from '@osrdata/app_core/dist/requests'
import { Point } from 'geojson'
import { ViewportProps } from 'react-map-gl'
import { catchable } from 'utils'

export const SCH_DEFAULT_VIEWPORT = {
  latitude: 46.39,
  longitude: 2.57,
  zoom: 5.44,
  bearing: 0,
  pitch: 0,
}

export const GEO_DEFAULT_VIEWPORT = {
  latitude: 46.92,
  longitude: 1.50,
  zoom: 5.46,
  bearing: 0,
  pitch: 0,
}

export const CHARTIS_SOURCES_IDS = {
  trackGeo: 'cassini_v2_rgi_track_geo',
  trackSch: 'cassini_v2_rgi_track_sch_flat',
  adv: 'cassini_v2_gaia_appareil_de_voie',
  advLine: 'cassini_v2_gaia_appareil_de_voie_lineaire_20',
  localisateur: 'cassini_v2_gaia_localisateur',
  pedale: 'cassini_v2_gaia_pedale',
  acces: 'cassini_v2_gaia_acces',
  bif: 'cassini_v2_gaia_point_remarquable',
} as const

export const MAPBOX_LAYER_IDS = {
  trackVp: 'track-vp',
  trackVs: 'track-vs',
  trackNameVS: 'track-name-vs',
  trackNameVP: 'track-name-vp',
  lineNumber: 'line-number',
  advCircle: 'adv-circle',
  advHighlight: 'adv-highlight',
  advLabel: 'adv-label',
  advLine: 'adv-line',
  localisateurCircle: 'localisateur-circle',
  localisateurHighlight: 'localisateur-highlight',
  localisateurLabel: 'localisateur-label',
  pedaleCircle: 'pedale-circle',
  pedaleHighlight: 'pedale-highlight',
  pedaleLabel: 'pedale-label',
  accesCircle: 'acces-circle',
  accesHighlight: 'acces-highlight',
  accesLabel: 'acces-label',
  bifCircle: 'bif-circle',
  bifHighlight: 'bif-highlight',
  bifLabel: 'bif-label',
}

export const INTERACTIVE_LAYER_IDS = [
  MAPBOX_LAYER_IDS.advCircle,
  MAPBOX_LAYER_IDS.advHighlight,
  MAPBOX_LAYER_IDS.advLabel,
  MAPBOX_LAYER_IDS.advLine,
  MAPBOX_LAYER_IDS.localisateurCircle,
  MAPBOX_LAYER_IDS.localisateurHighlight,
  MAPBOX_LAYER_IDS.localisateurLabel,
  MAPBOX_LAYER_IDS.pedaleCircle,
  MAPBOX_LAYER_IDS.pedaleHighlight,
  MAPBOX_LAYER_IDS.pedaleLabel,
  MAPBOX_LAYER_IDS.accesCircle,
  MAPBOX_LAYER_IDS.accesHighlight,
  MAPBOX_LAYER_IDS.accesLabel,
  MAPBOX_LAYER_IDS.bifCircle,
  MAPBOX_LAYER_IDS.bifHighlight,
  MAPBOX_LAYER_IDS.bifLabel,
]

const postReprojectPoints = (
  points: Point[],
  fromProjection: string,
  toProjection: string,
) => catchable(() => post<Point[]>(
  `/cassini-v2/locate/${fromProjection}/reproject_point/${toProjection}/`,
  points,
))

export const reprojectViewport = async (
  viewport: Partial<ViewportProps>,
  fromType: 'sch' | 'geo',
  toType: 'sch' | 'geo',
) => {
  const { latitude, longitude } = viewport
  if (!latitude || !longitude) return viewport
  const fromProjection = fromType === 'sch' ? 'rgi_track_sch_flat' : 'rgi_track_geo'
  const toProjection = toType === 'sch' ? 'rgi_track_sch_flat' : 'rgi_track_geo'
  const points = [{ type: 'Point', coordinates: [longitude, latitude] } as Point]
  const response = await postReprojectPoints(points, fromProjection, toProjection)

  if ('error' in response) return viewport

  return {
    ...viewport,
    longitude: response[0].coordinates[0],
    latitude: response[0].coordinates[1],
  }
}
