// import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { useSearchParams } from 'react-router-dom'
import schImage from 'assets/icons/sch-map.png'
import geoImage from 'assets/icons/geo-map.png'
import terms from 'assets/terms'
import { parseViewportToURL, reprojectViewport, SCH_DEFAULT_VIEWPORT } from 'services'
import ToolWrapper from './ToolWrapper'
import { ContentSignals } from '../contents/ContentsManager'

import './MapSelectionTool.scss'

export default function MapSelectionTool() {
  const [params, setSearchParams] = useSearchParams()
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  /** Set url params based on selected map (params updates are catched in ContentsManager) */
  const handleMapSelection = (map: 'sch' | 'geo' | 'truncate') => async () => {
    if (map === 'sch' || map === 'geo') {
      if (map === leftData.value?.type) return
      const newVp = await reprojectViewport(leftData.value?.vp, leftData.value.type, map)
      params.set('vl', `${map},${parseViewportToURL(newVp)}`)
      params.delete('vr')
    } else {
      const vp = leftData.value?.vp || rightData.value?.vp || SCH_DEFAULT_VIEWPORT
      params.set('vl', `sch,${parseViewportToURL(vp)}`)
      params.set('vr', `geo,${parseViewportToURL(vp)}`)
    }

    setSearchParams(params)
  }

  return (
    <ToolWrapper title={terms.ToolsPanel.maps.title}>
      <div className="maps-selection-wrapper">
        <button type="button" className="map-button" onClick={handleMapSelection('sch')}>
          <img src={schImage} alt="sch-view" />
          {terms.ToolsPanel.maps.sch}
        </button>
        <button type="button" className="map-button" onClick={handleMapSelection('geo')}>
          <img src={geoImage} alt="geo-view" />
          {terms.ToolsPanel.maps.geo}
        </button>
        {/* <button type="button" className="map-button truncate" onClick={handleMapSelection('truncate')}>
          <div>
            <img src={geoImage} alt="truncate-view-1" />
            <img src={schImage} alt="truncate-view-2" />
            <div className="shadow" />
            <PlusIcon />
          </div>
          {terms.ToolsPanel.maps.truncate}
        </button> */}
      </div>
    </ToolWrapper>
  )
}
