import { NavigationControl, ScaleControl, _useMapControl as useMapControl } from 'react-map-gl'
import { Signal } from '@preact/signals-core'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg'
import { ContentType } from 'types'
import { GEO_DEFAULT_VIEWPORT, SCH_DEFAULT_VIEWPORT } from 'services'

import './MapControls.scss'

type Props = {
  data: Signal<Omit<ContentType, 'id'>>
}

export default function MapControls({ data }: Props) {
  const defaultViewport = data.value.type === 'geo' ? GEO_DEFAULT_VIEWPORT : SCH_DEFAULT_VIEWPORT
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })

  const handleZoomOut = () => {
    data.value = { ...data.value, vp: defaultViewport }
  }

  return (
    <div className="map-controls" ref={containerRef}>
      {data.value.type === 'geo' && (
        <div className="scale-control flex-center">
          <ScaleControl maxWidth={70} unit="metric" style={{ position: 'relative' }} />
        </div>
      )}
      <div className="map-control-buttons">
        <div className="map-control-button">
          <button
            type="button"
            className="flex-center"
            onClick={handleZoomOut}
          >
            <ZoomOutIcon />
          </button>
        </div>
        <NavigationControl
          className="navigation-control"
          showZoom={false}
          style={{ position: 'relative' }}
        />
      </div>
    </div>
  )
}
