/* eslint-disable no-console */
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as LayerIcon } from 'assets/icons/layers.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as PageViewIcon } from 'assets/icons/pageview.svg'
import { ButtonSize } from 'components/button/Button'
import { PanelSignal } from '../panels/PanelsManager'
import LayerPanel from '../panels/layers/LayerPanel'

import './ToolsBar.scss'

export default function ToolsBar() {
  const { title, content } = PanelSignal
  const tools = [
    {
      label: terms.Common.search,
      icon: <SearchIcon />,
      onClick: () => {
        title.value = terms.Common.search
        content.value = <>TODO</>
      },
      enable: true,
    },
    {
      label: terms.Header.saveView,
      icon: <FavoriteIcon />,
      onClick: () => console.log('Save'),
      enable: false,
    },
    {
      label: terms.Header.createPerimeter,
      icon: <PlusIcon />,
      onClick: () => console.log('create Perimeter'),
      enable: false,
    },
    {
      label: terms.Header.layers,
      icon: <LayerIcon />,
      onClick: () => {
        title.value = terms.Header.layers
        content.value = <LayerPanel />
      },
      enable: true,
    },
    {
      label: terms.Header.createObject,
      icon: <ShareIcon />,
      onClick: () => console.log('Create Object'),
      enable: false,
    },
    {
      label: terms.Header.toScreen,
      icon: <PageViewIcon />,
      onClick: () => console.log('To Screen'),
      enable: false,
    },
  ]

  return (
    <div className="tools-bar">
      {tools.map(tool => (
        <Button
          key={tool.label}
          style={ButtonStyle.borderLessWhite}
          text={tool.label}
          size={ButtonSize.medium}
          icon={tool.icon}
          onClick={tool.onClick}
          disabled={!tool.enable}
        />
      ))}
    </div>
  )
}
