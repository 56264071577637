/* eslint-disable no-console */
import terms from 'assets/terms'
import { Button, ButtonStyle, ButtonSize } from 'components'
import ToolWrapper from './ToolWrapper'

export default function ContactTool() {
  const contact = [
    {
      label: terms.ToolsPanel.contact.suggest,
      onClick: () => console.log('suggest'),
      enable: true,
    },
    {
      label: terms.ToolsPanel.contact.bug,
      onClick: () => console.log('bug'),
      enable: true,
    },
  ]

  return (
    <ToolWrapper title={terms.ToolsPanel.contact.title}>
      {contact.map(tool => (
        <Button
          key={tool.label}
          style={ButtonStyle.borderLess}
          text={tool.label}
          size={ButtonSize.medium}
          onClick={tool.onClick}
          disabled={!tool.enable}
        />
      ))}
    </ToolWrapper>
  )
}
