import { ActionCreatorWithOptionalPayload, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastLevel, ToastSignal } from 'components'

/**
 * Produce a generic function to create an thunk that will handle error on api call
 * @param type thunk type
 * @param apiFunction callback api function
 * @param successAction action to dispatch on success
 * @param errorAction optional action to dispatch on error
 * @returns a thunk
 */
export const createApiThunk = (
  type: string,
  apiFunction: () => Promise<unknown>,
  successAction?: ActionCreatorWithOptionalPayload<unknown, string>,
  successMessage?: string,
  errorAction?: ActionCreatorWithOptionalPayload<unknown, string>,
) => createAsyncThunk(
  type,
  async (_, thunkApi) => {
    try {
      const response = await apiFunction()
      if (successMessage) {
        ToastSignal.value = { message: successMessage, severity: ToastLevel.SUCCESS }
      }
      if (successAction) thunkApi.dispatch(successAction(response))
      return response
    } catch (e) {
      if (errorAction) thunkApi.dispatch(errorAction(e))
      return thunkApi.rejectWithValue(e)
    }
  },
)()
