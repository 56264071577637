import { ReactElement, useRef, useState } from 'react'
import { auth } from '@osrdata/app_core'
import { store } from 'reducers/store'
import terms from 'assets/terms'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg'
import { ReactComponent as DisconnectIcon } from 'assets/icons/disconnect.svg'
import { useAppSelector, useClickOutside } from 'utils'

import './AccountMenu.scss'

type MenuItemType = {
  label: string;
  icon?: ReactElement;
  onClick: () => void;
}

const MENU_ITEMS: MenuItemType[] = [
  {
    label: terms.Common.disconnect,
    onClick: () => auth.logout()(store.dispatch),
  },
]

export default function AccountMenu(): ReactElement {
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const user = useAppSelector(state => state.user)
  const [menuDisplayed, setMenuDisplayed] = useState(false)

  const handleOpenMenu = () => {
    setMenuDisplayed(!menuDisplayed)
  }

  const handleCloseMenu = (next?: () => void) => {
    setMenuDisplayed(false)
    if (next !== undefined) next()
  }

  useClickOutside(menuWrapperRef, handleCloseMenu)

  return (
    <div className="account-menu" ref={menuWrapperRef}>
      <button type="button" onClick={handleOpenMenu}>
        {`${user.account.firstName} ${user.account.lastName}`}
        <ChevronDownIcon />
      </button>
      <ul className={menuDisplayed ? 'displayed' : ''}>
        {MENU_ITEMS.map(item => (
          <li key={item.label}>
            <a onClick={() => handleCloseMenu(item.onClick)} className="flex-center">
              <DisconnectIcon />
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
