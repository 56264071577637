/* eslint-disable max-len */
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { signal } from '@preact/signals-core'
import { ContentType } from 'types'
import { LayersSignal, getContentForParam, parseViewportToURL, SCH_DEFAULT_VIEWPORT, urlEqualsToViewport } from 'services'
import { Map } from './map'

import './ContentsManager.scss'

/**
 * Controls left and right content displayed in ContentsManager
 */
export const ContentSignals = {
  left: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
  right: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
}

export default function ContentsManager() {
  const [params, setSearchParams] = useSearchParams()
  const leftParam = params.get('vl')
  const rightParam = params.get('vr')
  const layerParams = params.get('l')
  const { data: leftData, element: leftElement } = ContentSignals.left
  const { data: rightData, element: rightElement } = ContentSignals.right

  /** Update signal data based on new params */
  const handleUpdateDataSignal = (newParams: string, key: 'left' | 'right' = 'left') => {
    const { type, vp } = getContentForParam(newParams)
    ContentSignals[key].data.value = { type, vp }
  }

  // Load left and/or right signals element when data changes
  useEffect(() => {
    if (leftData.value?.type) leftElement.value = <Map paramKey="vl" data={leftData} />
    if (rightData.value?.type) rightElement.value = <Map paramKey="vr" data={rightData} />
  }, [leftData.value, rightData.value])

  // Load layers signal when layer data changes
  useEffect(() => {
    if (LayersSignal.value?.length === 0) params.delete('l')
    else params.set('l', LayersSignal.value.join(','))

    setSearchParams(params)
  }, [LayersSignal.value])

  // Update left signal data when left params change
  // (Set default sch map if no params are present)
  useEffect(() => {
    if (!leftParam && !rightParam) {
      params.set('vl', `sch,${parseViewportToURL(SCH_DEFAULT_VIEWPORT)}`)
      setSearchParams(params)
      return
    }

    if (urlEqualsToViewport(leftParam, leftData.value)) return
    handleUpdateDataSignal(leftParam, 'left')
  }, [leftParam])

  // Update right signal data when right params change
  useEffect(() => {
    if (!rightParam || urlEqualsToViewport(rightParam, rightData.value)) return
    handleUpdateDataSignal(rightParam, 'right')
  }, [rightParam])

  // Update Layer signal data when layer params change
  useEffect(() => {
    if (!layerParams) return
    LayersSignal.value = layerParams.split(',')
  }, [layerParams])

  return (
    <div className={`contents-manager flex-center ${(!!leftElement.value && !!rightElement.value) ? ' truncate' : ''}`}>
      {leftElement.value}
      {rightElement.value}
    </div>
  )
}
