/* eslint-disable no-console */
import { auth } from '@osrdata/app_core'
import { store } from 'reducers/store'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as DisconnectIcon } from 'assets/icons/disconnect.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import { useEffect, useRef, useState } from 'react'
import AgentTool from './AgentTool'
import MapSelectionTool from './MapSelectionTool'
import ContactTool from './ContactTool'
import ContributeTool from './ContributeTool'
import HelpTool from './HelpTool'

import './ToolsPanel.scss'

export default function ToolsPanel() {
  const panelRef = useRef<HTMLDivElement>(null)
  const [hidden, setHidden] = useState(true)

  const handleToggle = () => {
    setHidden(!hidden)
  }

  useEffect(() => {
    if (hidden) {
      panelRef.current?.scrollTo(0, 0)
    }
  }, [hidden])

  return (
    <div className={`tools-panel hide-scroll${hidden ? ' hidden' : ''}`} ref={panelRef}>
      {hidden
        ? <BurgerIcon className="close-burger" onClick={handleToggle} />
        : <CloseIcon className="close-burger" onClick={handleToggle} />}
      <AgentTool />
      <MapSelectionTool />
      <ContactTool />
      <ContributeTool />
      <HelpTool />
      <Button
        text={terms.Common.disconnect}
        style={ButtonStyle.borderLess}
        icon={<DisconnectIcon />}
        onClick={() => auth.logout()(store.dispatch)}
      />
    </div>
  )
}
