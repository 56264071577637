import ToolsBar from './header/ToolsBar'
import ToolsPanel from './menu/ToolsPanel'
import PanelsManager from './panels/PanelsManager'
import ContentsManager from './contents/ContentsManager'

import './HomePage.scss'

export default function HomePage() {
  return (
    <div id="home" className="page">
      <ToolsBar />
      <ToolsPanel />
      <main>
        <ContentsManager />
        <PanelsManager />
      </main>
    </div>
  )
}
