import { MapDataSignal } from 'pages/home/panels/PanelsManager'
import { Source, Layer } from 'react-map-gl'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useMapURL } from 'utils'

export default function ADVLineLayer({ view, mapRef, params, visibility }: LayerProps) {
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.advLine,
    view,
    CHARTIS_SOURCES_IDS.advLine,
    mapRef,
    params,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <Source
      id={CHARTIS_SOURCES_IDS.advLine}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.advLine}
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor('#FF9313', hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        source-layer={CHARTIS_SOURCES_IDS.advLine}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        minzoom={9}
      />
    </Source>
  )
}
