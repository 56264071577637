/** Contents manager services */
import { ContentType } from 'types'
import { parseURLToViewport } from './params'

/**
 * Get ContentType object for param
 *
 * example :
 * string params looks like 'geo,0-0' if geo or sch
 * string params looks like 'doc,123' if doc
 *
 * @param para - param string to decode
 * @returns - ContentType object
 */
export const getContentForParam = (param: string): ContentType => {
  const decodedParam = decodeURIComponent(param)
  const [type, value] = decodedParam.split(',')

  switch (type) {
    case 'geo':
    case 'sch':
      return {
        type: type as ContentType['type'],
        vp: parseURLToViewport(value),
      }
    case 'doc':
      return {
        type: type as ContentType['type'],
        id: value,
      }
    default:
      return undefined
  }
}
