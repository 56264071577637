import { useSearchParams } from 'react-router-dom'
import { signal, Signal } from '@preact/signals-core'
import debounce from 'lodash/debounce'
import { geoMapStyle, schMapStyle } from 'assets/map'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import ReactMapGL, { MapRef, ViewportProps } from 'react-map-gl'
import { INTERACTIVE_LAYER_IDS, parseViewportToURL } from 'services'
import { transformRequest } from 'utils'
import { Loader } from 'components'
import { ContentType } from 'types'
import { MapDataSignal } from 'pages/home/panels/PanelsManager'
import GeoLayers from './GeoLayers'
import SchLayers from './SchLayers'
import MapControls from './controls/MapControls'

type MapProps = {
  data: Signal<Omit<ContentType, 'id'>>
  paramKey: 'vl' | 'vr'
}

export default function Map({ paramKey, data }: MapProps) {
  const LoadingSignal = useMemo(() => signal<boolean>(true), [])
  const mapRef = useRef<MapRef>()
  const [params, setSearchParams] = useSearchParams()
  const { type, vp } = data.value

  const handleUpdateUrlParams = useCallback(debounce((newVp: ViewportProps, oldParams: URLSearchParams) => {
    const newParams = new URLSearchParams(oldParams)
    newParams.set(paramKey, `${data.value.type},${parseViewportToURL(newVp)}`)
    setSearchParams(newParams)
  }, 500), [])

  const onViewportChange = (newViewport: ViewportProps) => {
    const newVp = { ...newViewport, transitionDuration: 0 }
    data.value = { type, vp: newVp }
    handleUpdateUrlParams(newVp, params)
  }

  // Display loader when map is loading data
  useEffect(() => {
    if (!mapRef.current) return undefined
    const startLoading = () => { LoadingSignal.value = true }
    const stopLoading = () => { LoadingSignal.value = false }

    mapRef.current.getMap().on('idle', stopLoading)
    mapRef.current.getMap().on('sourcedataloading', startLoading)

    return () => {
      mapRef.current?.getMap().off('idle', stopLoading)
      mapRef.current?.getMap().off('sourcedataloading', startLoading)
    }
  }, [mapRef.current])

  return (
    <>
      {LoadingSignal.value && <Loader variant="small" />}
      <ReactMapGL
        {...vp}
        key={type}
        width="100%"
        height="100%"
        onViewportChange={onViewportChange}
        clickRadius={2}
        transformRequest={transformRequest}
        interactiveLayerIds={INTERACTIVE_LAYER_IDS}
        mapStyle={type === 'geo' ? geoMapStyle : schMapStyle}
        ref={mapRef}
        onHover={e => { MapDataSignal.hoveredObjects.value = e.features }}
      >
        {type === 'geo' ? <GeoLayers mapRef={mapRef} /> : <SchLayers mapRef={mapRef} />}
        <MapControls data={data} />
      </ReactMapGL>
    </>
  )
}
