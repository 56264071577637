import { ViewportProps } from 'react-map-gl'
import { ContentType } from 'types'

/**
 * Parse URL string to ViewportProps matching this format : '0.0_0.0_0.0_0_0'
 *
 * @param stringParams - URL string params to parse
 * @returns - ViewportProps object
 */
export const parseURLToViewport = (stringParams: string): Partial<ViewportProps> => {
  if (!stringParams) return null
  const match = stringParams.match(/(-?\d+\.\d+)_(-?\d+\.\d+)_(\d+\.\d+)_(-?\d+)_(-?\d+)/)
  if (!match) return null
  const [, longitude, latitude, zoom, bearing, pitch] = match
  return ({
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    zoom: parseFloat(zoom),
    bearing: parseInt(bearing, 10),
    pitch: parseInt(pitch, 10),
  })
}

/**
 * Parse ViewportProps to URL string
 *
 * @param viewport - ViewportProps object to parse
 * @returns - URL string params with format '0.0_0.0_0.0_0_0'
 */
export const parseViewportToURL = (viewport: Partial<ViewportProps> = {}): string => (
  // eslint-disable-next-line max-len
  `${viewport.longitude}_${viewport.latitude}_${viewport.zoom}_${(Math.floor(viewport.bearing))}_${Math.floor(viewport.pitch)}`
)

/**
 * Check if URL params match content data type and viewport
 * @param params - URL params
 * @param content - Content data
 * @returns - Boolean
 */
export const urlEqualsToViewport = (params: string, content: Partial<ContentType>): boolean => {
  const parsedLeftData = parseViewportToURL(content?.vp)
  return params === `${content?.type},${parsedLeftData}`
}
