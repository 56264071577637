import { MutableRefObject } from 'react'
import { Source, Layer, MapRef } from 'react-map-gl'
import { MAPBOX_LAYER_IDS } from 'services'
import { useMapURL } from 'utils/map'

interface Props {
  mapRef: MutableRefObject<MapRef>
  source: string
}

export default function TrackLayer({ mapRef, source }: Props) {
  const url = useMapURL(source, 'full', source, mapRef)
  return (
    <Source
      id={source}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.trackVp}
        type="line"
        paint={{ 'line-color': '#0088ce', 'line-width': 2 }}
        layout={{ visibility: 'visible' }}
        filter={['any',
          ['==', ['get', 'type_voie'], 'VP'], ['==', ['get', 'type_voie'], 'VPL'], ['==', ['get', 'type_voie'], 'VPA'],
        ]}
        source-layer={source}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.trackVs}
        type="line"
        paint={{ 'line-color': 'grey', 'line-width': 1 }}
        layout={{ visibility: 'visible' }}
        filter={['==', ['get', 'type_voie'], 'VS']}
        source-layer={source}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.trackNameVS}
        type="symbol"
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'symbol-spacing': 1000,
          'text-size': 12,
          'text-allow-overlap': false,
          'text-field': '{voie}',
          visibility: 'visible',
        }}
        paint={{
          'text-color': '#555',
          'text-halo-width': 2,
          'text-halo-color': 'rgba(255,255,255,0.75)',
          'text-halo-blur': 1,
        }}
        source={source}
        source-layer={source}
        filter={['==', ['get', 'type_voie'], 'VS']}
        minzoom={12}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.trackNameVP}
        type="symbol"
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'text-size': 13,
          'text-allow-overlap': false,
          'symbol-spacing': 1000,
          'text-field': '{voie}',
          visibility: 'visible',
        }}
        paint={{
          'text-color': 'black',
          'text-halo-width': 1.5,
          'text-halo-color': 'rgba(255,255,255,1)',
        }}
        source={source}
        source-layer={source}
        filter={['any',
          ['==', ['get', 'type_voie'], 'VP'], ['==', ['get', 'type_voie'], 'VPL'], ['==', ['get', 'type_voie'], 'VPA'],
        ]}
        minzoom={12}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.lineNumber}
        type="symbol"
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'symbol-spacing': 2000,
          'text-size': 13,
          'text-field': '{ligne}',
          visibility: 'visible',
        }}
        paint={{
          'text-color': 'black',
          'text-halo-width': 1.5,
          'text-halo-color': 'rgba(255,255,255,1)',
        }}
        source={source}
        source-layer={source}
        minzoom={12}
      />
    </Source>
  )
}
