import { MutableRefObject } from 'react'
import { MapRef } from 'react-map-gl'
import { CHARTIS_SOURCES_IDS, isLayerSelected } from 'services'
import {
  AccesLayer, ADVLayer, ADVLineLayer, BifLayer, LocalisateurLayer, PedaleLayer, TrackLayer,
} from './layers'

interface Props {
  mapRef?: MutableRefObject<MapRef>
}
export default function SchLayers({ mapRef }: Props) {
  return (
    <>
      <TrackLayer
        mapRef={mapRef}
        source={CHARTIS_SOURCES_IDS.trackSch}
      />
      <ADVLineLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <ADVLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <LocalisateurLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('localisateur')}
      />
      <PedaleLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('pedale')}
      />
      <AccesLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('acces')}
      />
      <BifLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat_centroid"
        visibility={isLayerSelected('bif')}
      />
    </>
  )
}
